import React, { useState } from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "./../../utils/Helpers/Format";
import Thunder from '../../assets/ThunderIcon.svg'

const IncentiveCard = props => {
  const [isOpen, setIsOpen] = useState(false)
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }
  
  let name = incentive.grantor + " " + incentive.name || "";
  let description = incentive.description || "";

  const handleCardClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    window.open(incentive.details_url, '_blank').focus()
  }

  const handleTextClick = (e, action) => {
    e.stopPropagation()
    e.preventDefault()
    setIsOpen(action)
  }

  const truncateTxt = (str) => {
    let n = 132

    if(str.length > n){
      if(isOpen){
        return <>{str} <span onClick={e => handleTextClick(e, false)} style={{color:'#E93047', cursor: 'pointer'}}>less</span> </>
      } else {
        return <>{str.substr(0, n-1)}... <span onClick={e => handleTextClick(e, true)} style={{color:'#E71D34', cursor: 'pointer'}}>more</span></>
      }
    } else {
      return str
    }
  }
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  let renderCard = incentive ? (
    <div
      className="cardIncentive"
      style={{borderTop: `8px solid ${incentive.grantor === "Glendale Water & Power" ? '#E39C25' : '#005996'}`, maxHeight: `${isOpen ? '' : '387px'}`}}
      onClick={e => handleCardClick(e)}
    >
      <div className="IncentiveCardBody">
        <p className="h2 card-preTitle" >{incentive.type}</p>
        <div className="card-price">
          {incentive.typical_amount === "N/A" && <img alt="Thunder" src={Thunder} style={{width: '32px', height: 'auto'}}/>}
          <p className="h1 card-title">
            {amount ? FormatAsDollars(amount) : amountDescription}
          </p>
        </div>
        <p className="h6 card-subTitle">{name}</p>
      </div>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
        <div className="IncentiveCardBottom">{truncateTxt(description)}</div>
      </div>
    </div>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
