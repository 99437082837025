import React from "react";
import "./Footer.scss";
import { FormattedMessage } from "react-intl";
import logo from "../../../assets/images/logo.png";
import mail from "../../../assets/images/icons/mail.svg";
import facebook from "../../../assets/images/icons/facebook.svg";
import twitter from "../../../assets/images/icons/twitter.svg";
import instagram from "../../../assets/images/icons/instagram.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="container padding-left pt-2 pb-2">
        <div className="row">
          <div className="col-md-3 mb-3">
            <a
              href="https://www.glendaleca.gov/government/departments/glendale-water-and-power"
              rel="noopener noreferrer"
              className="navbar-brand"
              target="_blank"
            >
              <img
                src={logo}
                alt={process.env.REACT_APP_FULL_COMPANY_NAME}
                className="img-fluid"
                style={{ maxWidth: "116px" }}
              />
            </a>
          </div>
          <div className="col-md-3 mt-3 mb-3">
            <h2>
              <FormattedMessage
                id="footer.social"
                defaultMessage="Social"
                description="Social Header"
              />
            </h2>
            <div className="mt-3">
              <a href={`${process.env.REACT_APP_COMPANY_CONTACT}`}>
                <img
                  alt={`${process.env.REACT_APP_COMPANY_CONTACT_DISPLAY}`}
                  src={mail}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_COMPANY_FACEBOOK}`}
                className="ml-3"
              >
                <img
                  alt={`${process.env.REACT_APP_COMPANY_FACEBOOK_DISPLAY}`}
                  src={facebook}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_COMPANY_TWITTER}`}
                className="ml-3"
              >
                <img
                  alt={`${process.env.REACT_APP_COMPANY_TWITTER_DISPLAY}`}
                  src={twitter}
                />
              </a>
              <a
                href={`${process.env.REACT_APP_COMPANY_INSTAGRAM}`}
                className="ml-3"
              >
                <img
                  alt={`${process.env.REACT_APP_COMPANY_INSTAGRAM_DISPLAY}`}
                  src={instagram}
                />
              </a>
            </div>
          </div>
          <div className="col-md-3 mt-3 mb-3">
            <h2>
              <FormattedMessage
                id="footer.address"
                defaultMessage="Address"
                description="Address Header"
              />
            </h2>
            <div className="mt-3">
              <span>
                {process.env.REACT_APP_PAGES_MAP_ROUTE_START_ADDRESS}
                <br />
                {process.env.REACT_APP_PAGES_MAP_ROUTE_END_ADDRESS}
              </span>
            </div>
          </div>
          <div className="col-md-3 mt-3 mb-3">
            <h2>
              <FormattedMessage
                id="footer.phone"
                defaultMessage="Phone"
                description="Phone Header"
              />
            </h2>
            <div className="mt-3">
              <span>(855) 550-4497</span>
            </div>
          </div>
        </div>
      </div>
      <div className="container no-border-bottom">
       <div className="disclaimer_container">
                     <DisclaimerComponent clientName="GWP" image="zappyride" />
       </div>
      </div>
    </div>
  );
};

export default Footer;
