import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from 'react-intl';

const ChargerSortControls = () => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const updateSort = value => {
    let newPrefs = {
      chargerSortType: value.split("-")[0],
      chargerSortDirection: value.split("-")[1]
    };
    userPrefs.set(newPrefs);
  };

  let sortOptionValue = [
    userPrefs.get("chargerSortType"),
    userPrefs.get("chargerSortDirection")
  ].join("-");

  return (
    <div className="EVsSort">
      <form className="form-inline">
        <label htmlFor="vehicle-sort-dropdown" className="mr-2" style={{fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: 'black'}}>
          <FormattedMessage
            id="evSort.titleFilter"
            defaultMessage="FILTER BY"
            description="EV Sort By Title"
          />        
        </label>
        <select
          id="vehicle-sort-dropdown"
          className="form-control"
          style={{borderRadius: '0px', width: '160px', height: '48px'}}
          value={sortOptionValue}
          onChange={e => updateSort(e.target.value)}
        >
          <option value="price-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.priceLowest", defaultMessage: "Price: Lowest"}): "Price: Low to High"}
          </option>
          <option value="price-desc">
            {intl.formatMessage ? intl.formatMessage({ id: "evSort.priceHighest", defaultMessage: "Price: Highest"}): "Price: High to Low"}
          </option>
          <option value="cord_length-asc">
            {intl.formatMessage ? intl.formatMessage({ id: "homeChargers.cordLengthLowest", defaultMessage: "Cord Length: Lowest"}) : "Cord Length: Low to High"}
          </option>
          <option value="cord_length-desc">
            {intl.formatMessage ? intl.formatMessage({ id: "homeChargers.cordLengthHighest", defaultMessage: "Cord Length: Highest"}) : "Cord Length: High to Low"}
          </option>
        </select>
      </form>
    </div>
  );
};

export default ChargerSortControls;
