import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "./../../components/IncentivesPreferences/IncentivePreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from "react-helmet";

const Incentives = ({ electricVehicles, incentives, ip, uuid }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_PAGES_INCENTIVES_TITLE}</title>
      </Helmet>
      <section className="container" style={{background: '#F6F6F6'}}>
        <div className="row incentive-prefs">
          <div className="col-sm-12" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <h1 style={{fontFamily: "questa", fontWeight: '400', fontSize: '40px', lineHeight: '50px'}}>
              <FormattedMessage 
                id="incentivesPage.title"
                defaultMessage="Electric Vehicle and Charger Incentives"
                description="Electric Vehicle Incentives"
              />
            </h1>
            <p className="lead" style={{fontFamily: 'proxima-nova',fontWeight: '400', fontSize: '20px', lineHeight: '24px', textAlign: 'center', maxWidth: '57ch', color: '#707070', paddingTop: '16px', marginBottom: '46px'}}>
              <FormattedMessage 
                id="incentivesPage.subTitle"
                defaultMessage="You may be eligible for a range of incentives, including EV rebates, EV tax credits, and various other benefits."
                description="Incentives Subtitle"
              />
            </p>
            <IncentivePreferences
              incentives={incentives}
              electricVehicles={electricVehicles}
              titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentivesBtn", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
              btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentivesBtn", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
            />
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
