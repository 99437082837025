import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "../../../client_customizations/assets/images/icons/charger-pin-public.svg"
import ChargingPinHighPower from "../../../client_customizations/assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "../../../client_customizations/assets/images/icons/charger-pin-private.svg"

const iconSrc = type => {
  switch (type) {
    case "public":
      return ChargingPinPublic;
    case "highPower":
      return ChargingPinHighPower;
    case "private":
    default:
      return ChargingPinDefault;
  }
};

// NOTE: refactored this into its own component to mitigate this:
// https://github.com/google-map-react/google-map-react/issues/583
const ChargingStationsMapMarker = ({
  station,
  ignoreSuperchargerStations,
  selectedStation,
  onClick,
  onMouseOver
}) => {
  let iconType =
    Number(station.ev_dc_fast_num) && !ignoreSuperchargerStations
      ? "highPower"
      : station.access_code === "public"
        ? "public"
        : "default";

  let imageStyle = {
    width: "25px"
  };
  if (selectedStation && selectedStation.id === station.id) {
    imageStyle.filter = "brightness(85%)";
  }

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)"
  };

  return (
    <span station={station} style={placeStyle} onClick={onClick} onMouseOver={onMouseOver}>
      <img src={iconSrc(iconType)} style={imageStyle} alt="" />
    </span>
  );
};

ChargingStationsMapMarker.propTypes = {
  station: PropTypes.shape({
    ev_dc_fast_num: PropTypes.number,
    access_code: PropTypes.string
  }),
  ignoreSuperchargerStations: PropTypes.bool,
  selectedStation: PropTypes.object,
  onClick: PropTypes.func
};

ChargingStationsMapMarker.defaultProps = {
  station: {},
  ignoreSuperchargerStations: false,
  selectedStation: null,
  onClick: () => {}
};

export default ChargingStationsMapMarker;
