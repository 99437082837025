import React from "react";
import PropTypes from "prop-types";
import LinkCard from "../../../../components/LinkCard/LinkCard";
import linkIcon from "../../../assets/images/icons/arrow-right.svg";
import { FormattedMessage } from "react-intl";

const HomepageBigPromise = ({ homePageBannerImage }) => {
  return (
    <section className="container p-0">
      <div
        className="big-promise hero-image"
        style={{
          backgroundImage: `url(${homePageBannerImage})`,
        }}
      >
        <div className="hero-message">
          <h1>
            <FormattedMessage
              id="homepage.hero-message.header"
              defaultMessage="Your Guide to Electric Vehicles."
              description="Homepage Hero Message Title"
            />
          </h1>
          <p>
            <FormattedMessage
              id="homepage.hero-message.paragraph"
              defaultMessage="Estimate and compare costs, savings, EV incentives, and more."
              description="Homepage Hero Message Paragraph"
            />
          </p>
          <a href="/vehicles">
            <FormattedMessage
              id="homepage.hero-message.linke"
              defaultMessage="Find your electric vehicle"
              description="Homepage Hero Message Link"
            />
            <img alt="link icon" src={linkIcon} />
          </a>
        </div>
      </div>

      <div id="link-card-buttons">
        <div className="col">
          <div className="link-card-container">
            <LinkCard type="DISCOVER_ELECTRIC_VEHICLES" />
            <LinkCard type="MAXIMIZE_INCENTIVES" />
            <LinkCard type="LOCATE_CHARGING_STATIONS" />
            <LinkCard type="SHOP_HOME_CHARGERS" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string,
};
