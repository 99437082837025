import React, { useContext } from "react";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import Switch from "react-switch";
import ToolTip from "../shared/ToolTip/ToolTip";
// import IconLocal from "../../client_customizations/assets/images/icons/icon-local.png";
import IconBEV from "../../client_customizations/assets/images/icons/icon-electric.png";
import IconPHEV from "../../client_customizations/assets/images/icons/icon-hybrid.png";
import SelectVehicleMakeFilter from "../InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter";
import SelectVehicleAgeFilter from "../InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter";
import "./EVFilterControls.scss";

import { FormattedMessage, useIntl } from "react-intl";

const EVFilterControls = ({ vehicles, usedEvs }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const getMessage = React.useCallback(
    ({ id, defaultMessage }) => {
      return intl.formatMessage
        ? intl.formatMessage({
            id,
            defaultMessage,
          })
        : defaultMessage;
    },
    [intl]
  );

  // const locallyAvailable = userPrefs.get("locallyAvailableVehicleFilter");
  const formFactorFilterData = userPrefs.get("vehicleFormFactorFilters");
  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");

  const vehicleType = React.useMemo(
    () =>
      !intl.formatMessage
        ? {}
        : {
            Sedan: intl.formatMessage({
              id: "vehicle.type.sedan",
              defaultMessage: "Sedan",
            }),
            Hatchback: intl.formatMessage({
              id: "vehicle.type.hatchback",
              defaultMessage: "Hatchback",
            }),
            Coupe: intl.formatMessage({
              id: "vehicle.type.coupe",
              defaultMessage: "Coupe",
            }),
            Crossover: intl.formatMessage({
              id: "vehicle.type.crossover",
              defaultMessage: "Crossover",
            }),
            Minivan: intl.formatMessage({
              id: "vehicle.type.minivan",
              defaultMessage: "Minivan",
            }),
            SUV: intl.formatMessage({
              id: "vehicle.type.suv",
              defaultMessage: "SUV",
            }),
            Wagon: intl.formatMessage({
              id: "vehicle.type.wagon",
              defaultMessage: "Wagon",
            }),
            Truck: intl.formatMessage({
              id: "vehicle.type.truck",
              defaultMessage: "Truck",
            }),
          },
    [intl]
  );

  const updateFormFactorFilter = (key) => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
      vehicleFormFactorFilters: newFilters,
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName =
        filterKey === "suv"
          ? "SUV"
          : `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          {vehicleType[filterName]}
        </button>
      );
    }
  );

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce(function (
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters,
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "bev":
          filterName = getMessage({
            id: "vehicle.allElectric",
            defaultMessage: "All Electric",
          });
          filterIconSrc = IconBEV;
          break;
        case "phev":
          filterName = getMessage({
            id: "vehicle.plugInHybrid",
            defaultMessage: "Plug in Hybrid",
          });
          filterIconSrc = IconPHEV;
          break;
        default:
      }

      return (
        <div key={i} className="filter-switch">
          <span className="badge">
            <img src={filterIconSrc} alt="" />
          </span>
          <span className="name">{filterName}</span>
          <div className="switch">
            <Switch
              aria-label={filterName}
              id={filterKey}
              checked={fuelTypeFilterData[filterKey] ? true : false}
              onChange={updateFuelTypeFilter}
              onColor="#f89029"
              offColor="#005996"
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <div className="clearfix"></div>
        </div>
      );
    }
  );

  return (
    <div className="input-well">
      <p className="h2">
        <FormattedMessage
          id="evfilter"
          defaultMessage="Filter"
          description="Filter Title"
        />
      </p>
      <form>
        <div style={{ height: ".5rem" }} />

        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
            <ToolTip
              message={getMessage({
                id: "evfilter.fuelTooltip",
                defaultMessage:
                  "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.",
              })}
              id="fuel_tooltip"
            />
          </span>
          <div style={{ height: "1rem" }} />

          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>

        <div className="form-group">
          <span className="label-style">
            <FormattedMessage
              id="evfilter.type"
              defaultMessage="Type"
              description="Type"
            />
          </span>
          <div style={{ height: ".5rem" }} />
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>
        {usedEvs && (
          <>
            <div className="form-group">
              <SelectVehicleMakeFilter vehicles={vehicles} />
            </div>

            <div className="form-group">
              <SelectVehicleAgeFilter />
            </div>
          </>
        )}
      </form>
      <br />
      <p className="legal-disclaimer">
        <FormattedMessage
          id="evs.disclaimer"
          defaultMessage="Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer."
          description="Vehicles Disclaimer"
          values={{
            company: process.env.REACT_APP_FULL_COMPANY_NAME,
          }}
        />
      </p>
    </div>
  );
};

export default EVFilterControls;
