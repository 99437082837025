import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../../../components/VehicleCarousel/VehicleCarousel";
import ChargingMap from "../../../components/LocationMap/ChargingMap";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl";

const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  ip,
  uuid,
}) => {
  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  };

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_TITLE;
    setElectricVehicles();
  });

  const renderDetails =
    electricVehicles && incentives ? (
      <>
        <VehiclesCarousel electricVehicles={getLocalVehicles(vehicles)} />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
      <HomepageBigPromise homePageBannerImage={homePageBannerImage} />
      {renderDetails}
      <div id="HomepageChargingStations">
        <section className="container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingBottom: "56px",
            }}
          >
            <p
              style={{
                fontFamily: "Questa",
                fontWeight: "400",
                fontSize: "32px",
                lineHeight: "40px",
                textAlign: "center",
                color: "#000",
              }}
            >
              <FormattedMessage
                id="homepage.chargingStations.title"
                defaultMessage="Recharge on the go, wherever you go."
                description="Charging Stations Section Title"
              />
            </p>
            <p
              style={{
                fontFamily: "sans-serif",
                fontSize: "20px",
                lineHeight: "24px",
                color: "#707070",
                textAlign: "center",
                maxWidth: "76ch",
              }}
            >
              <FormattedMessage
                id="homepage.charginStations.subTitle"
                defaultMessage="With new stations powering on every day, it’s easier than ever to drive far and recharge. Enter your location to see all of the charging stations near you."
                description="Charging Stations Section SubTitle"
              />
            </p>
          </div>
          <ChargingMap userLocation={userLocation} />
        </section>
      </div>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
