import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";
import {Helmet} from "react-helmet";

import EVCatalog from "./../../components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import EVFilterControls from "./../../components/EVFilterControls/EVFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from "react-intl";

const EVs = ({ electricVehicles, ip, uuid }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const intl = useIntl();

  const getMessage = React.useCallback(
    ({ id, defaultMessage }) => {
      return intl.formatMessage
        ? intl.formatMessage({
            id,
            defaultMessage,
          })
        : defaultMessage;
    },
    [intl]
  );

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <MatchScoreOptions />
      <EVFilterControls vehicles={electricVehicles} />
    </>
  );

  return (
    <section className="container" id="EVs">
      <Helmet>
        <title> {process.env.REACT_APP_PAGES_VEHICLES_TITLE} </title>
      </Helmet>
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "questa",
                fontWeight: "400",
                fontSize: "40px",
                lineHeight: "40px",
                textAlign: "center",
                color: "#000",
              }}
            >
              <FormattedMessage
                id="evs.welcomeTitle"
                defaultMessage="Discover New Vehicles"
                description="Discover New Vehicless"
              />
            </p>
            <p
              style={{
                fontFamily: 'proxima-nova',
                fontSize: "20px",
                lineHeight: "24px",
                color: "#707070",
                textAlign: "center",
                maxWidth: "76ch",
              }}
            >
              <FormattedMessage
                id="evs.welcomeSub"
                defaultMessage="Tell us a little bit about your driving style and preferences so we can {lineBreak} surface the EVs that might best fit your lifestyle!"
                description="Description of Colton Recharged"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={getMessage({
              id: "evs.buttonMatchScoreAndFilters",
              defaultMessage: "Match Score and Filters",
            })}
            titleText={getMessage({
              id: "evs.matchScoreAndFilters",
              defaultMessage: "Refine Match Score and Filters",
            })}
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />
            {time}.
          </span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <EvSortControls />
              </div>
            </div>
          </div>
          <EVCatalog
            vehicles={electricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
