import React from "react";
import PropTypes from "prop-types";

import "./LinkCard.scss";

import DISCOVER_ELECTRIC_VEHICLES from "../../client_customizations/assets/images/icons/discover-electric-vehicles.svg";
import MAXIMIZE_INCENTIVES from "../../client_customizations/assets/images/icons/saved_search.svg";
import LOCATE_CHARGING_STATIONS from "../../client_customizations/assets/images/icons/ev_station.svg";
import SHOP_HOME_CHARGERS from "../../client_customizations/assets/images/icons/electrical_services.svg"
import { Link } from "react-router-dom";
import SmoothScroll from "./../../utils/Helpers/SmoothScroll";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type }) => {
  let src, link, scrollTo, title;

  switch (type) {
    case "DISCOVER_ELECTRIC_VEHICLES":
      src = DISCOVER_ELECTRIC_VEHICLES;
      scrollTo = "HomepageVehiclesCarousel";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="DISCOVER {lineBreak} ELECTRIC VEHICLES"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "MAXIMIZE_INCENTIVES":
      src = MAXIMIZE_INCENTIVES;
      scrollTo = "HomepageIncentives";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.discoverIncentives"
            defaultMessage="MAXIMIZE {lineBreak} INCENTIVES"
            description="HomePage Linkcard Discover Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "LOCATE_CHARGING_STATIONS":
      src = LOCATE_CHARGING_STATIONS;
      scrollTo = "HomepageChargingStations";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.locateChargingStations"
            defaultMessage="LOCATE {lineBreak} CHARGING STATIONS"
            description="HomePage Linkcard Locate Charging Stations"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "SHOP_HOME_CHARGERS":
      src = SHOP_HOME_CHARGERS;
      link = "/home-chargers";
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.homeChargers"
            defaultMessage="SHOP {lineBreak} HOME CHARGERS"
            description="HomePage Linkcard Home Chargers"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  const iconMask = `url(${src})`;

  return scrollTo ? (
    <div className="link-card">
      <div className="evc-card" onClick={e => SmoothScroll(scrollTo)}>
        <div>
          <div className="icon" style={{maskImage: iconMask, WebkitMaskImage: iconMask}}/>
          <p className="h6">{title}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className="link-card">
      <Link to={link} className="evc-card">
        <div>
        <div className="icon" style={{maskImage: iconMask, WebkitMaskImage: iconMask}}/>
          <p className="h6">{title}</p>
        </div>
      </Link>
    </div>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
