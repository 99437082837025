import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";


import ChargerCatalog from "../../components/ChargerCatalog/ChargerCatalog"
import ChargerSortControls from "./../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "./../../components/HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
import { FormattedMessage} from 'react-intl'
import { Helmet } from "react-helmet";

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
  const [ev, setEv] = useState(null)
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE;
  });

  if (!electricVehicles) {
    return null;
  }

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <p style={{fontWeight: '400', fontSize: '20px', fontHeight: '24px', marginBottom: '32px'}}>
        <FormattedMessage
          id="homeChargers.chargersFound"
          defaultMessage="{length} Chargers Found"
          description="Chargers Found"
          values={{
            length: homeChargers.length
          }}
        />
      </p>
      <HomeChargersFilterControls electricVehicles={electricVehicles} setEv={setEv}/>
    </>
  );

  return (
    <section className="container">
      <Helmet>
        <title>{process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE}</title>
      </Helmet>
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h2 style={{fontFamily: "questa",fontWeight: '400', fontSize: '40px', lineHeight: '50px'}}>
            <FormattedMessage
              id="homeChargers.title"
              defaultMessage="Home Chargers"
              description="Home Chargers Title"
            />
          </h2>
        </div>
      </div>

      <div className="row" style={{justifyContent: "center", marginBottom: '42px'}}>
        <p className="lead text-center" style={{fontFamily: "proxima-nova", textAlign: 'center', maxWidth: '70ch', color: '#707070', padding: 0, margin: 0, lineHeight: '24px', fontSize: 20}}>
          <FormattedMessage
            id="homeChargers.subTitle"
            defaultMessage="Below are some home chargers for you to consider. Select the make and model of the car you are considering to see an estimate of the charging time required by charger."
            description="Below are some home chargers for you to consider. Select the make and model of the car you are considering to see an estimate of the charging time required by charger.t"
          />
        </p>
      </div>

      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText="Filters"
            titleText="Refine Filters"
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="home-chargers-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
            id="results.updated"
            defaultMessage="Results have been updated as of "
            description="Results have been updated as of "
            />{time}.
          </span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              <div className="pull-right">
                <ChargerSortControls />
              </div>
            </div>
          </div>
          <ChargerCatalog
            homeChargers={homeChargers}
            selectedVehicle={ev}
          />
        </div>
      </div>
    </section>
  );
};

export default HomeChargers;

HomeChargers.propTypes = {
  electricVehicles: PropTypes.array,
  homechargers: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};