import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from 'react-intl';
import Caret from '../../assets/caretDropdown.svg'
import CaretBlack from '../../assets/caretBlack.svg'

const ShowHideButton = ({
  children,
  buttonText,
  displayChildrenOnlyOnTablet,
  displayChildrenOnlyOnMobile,
  displayAlertOnlyOnMobile,
  isSolid, 
  isFullWidth,
  margin
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const toggleButton = () => {
    setShowChildren(!showChildren);
  };
  const intl = useIntl()


  let renderChildren = showChildren ? children : null;
  let renderButtonText = showChildren
    ? intl.formatMessage ? intl.formatMessage({ id: "graph.hide", defaultMessage: "Hide"}) + buttonText: "Hide" + buttonText
    : intl.formatMessage ? intl.formatMessage({ id: "graph.show", defaultMessage: "Show"}) + buttonText: "Show" + buttonText;

  let button = (
    <button
      type="button"
      className="btn"
      onClick={toggleButton}
      style={{
        border: "1px solid #DBDBDB",
        margin: margin || "32px 5px 10px 0",
        fontSize: "0.85rem",
        color: '#205694',
        fontWeight: '600',
        borderRadius: 0,
        padding: '8px 60px',
        background: 'transparent',
        position: 'relative'
      }}
    >
      {buttonText ? buttonText : renderButtonText}
      <img src={Caret} alt="caret" style={{position: 'absolute', right: 0, top: 0, margin: '4px 8px', transition: 'all ease 0.3s', transform: `${showChildren ? 'rotate(180deg)' : ''}`}} />
    </button>
  );

  let buttonTwo = (
    <button
      type="button"
      className="btn"
      onClick={toggleButton}
      style={{
        border: "1px solid #005996",
        margin: margin || "32px 5px 10px 0",
        fontWeight: "600",
        fontSize: "16px",
        color: '#005996',
        borderRadius: 0,
        padding: '8px 40px',
        background: 'transparent',
        position: 'relative'
      }}
    >
      {buttonText ? buttonText : renderButtonText}
    </button>
  );

  let buttonThree = (
    <button
      type="button"
      className="btn"
      onClick={toggleButton}
      style={{
        borderBottom: "1px solid #DADADA",
        margin: margin || "32px 5px 10px 0",
        fontWeight: "400",
        width: '100%',
        fontSize: "16px",
        color: '#000',
        textAlign: 'start',
        borderRadius: 0,
        padding: '8px 37px',
        background: 'transparent',
        position: 'relative'
      }}
    >
      <img src={CaretBlack} alt="caret" style={{position: 'absolute', left: 0, top: 0, margin: '17px 0 0 4px', transition: 'all ease 0.3s', transform: `${showChildren ? 'rotate(180deg)' : ''}`}} />
      {buttonText ? buttonText : renderButtonText}
    </button>
  );

  let desktopClasses = "show-full-list-container d-none d-md-block";
  let mobileClasses = "d-block d-md-none";
  if (displayChildrenOnlyOnTablet) {
    desktopClasses = "show-full-list-container d-none d-lg-block";
    mobileClasses = "d-block d-lg-none";
  }

  return displayChildrenOnlyOnTablet ||
    displayChildrenOnlyOnMobile ||
    displayAlertOnlyOnMobile ? (
    <>
      <div className={desktopClasses}>
        {isSolid ? buttonTwo : isFullWidth ?  buttonThree: button}
        {renderChildren}
      </div>
      <div className={mobileClasses}>
        {displayChildrenOnlyOnMobile && children}
        {displayChildrenOnlyOnTablet && children}
        {displayAlertOnlyOnMobile && (
          <div className="input-well">
            <p>
              <FormattedMessage
                  id="ev.carDetails.mobile"
                  defaultMessage="For more details including charts {amp} calculations, please view this page on a desktop."
                  description="Car Details Mobile subtitle"
                  values={{
                    amp: "&",
                  }}
                />
            </p>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="show-full-list-container">
      {isSolid ? buttonTwo : isFullWidth ?  buttonThree: button}
      {renderChildren}
    </div>
  );
};

export default ShowHideButton;

ShowHideButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  displayChildrenOnlyOnTablet: PropTypes.bool,
  displayChildrenOnlyOnMobile: PropTypes.bool,
  displayAlertOnlyOnMobile: PropTypes.bool
};
