import React, { useState, useContext } from "react";
import { ValidateFiveDigitCode } from "../../../utils/Helpers/PostalCodeValidator";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormattedMessage } from "react-intl";

const InputZipcodeForChargingMap = () => {
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get("zipcode")
  );

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = "input-zipcode-for-charging-map";
  const idAria = id + "-help";

  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return;

    if (ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating) {
      async function asyncCall() {
        await userPrefs.set({ workingZipcode: workingZipcode });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall();
    }
  };

  return (
    <>
      <div>
        <div className="form-group">
          <label style={{ textTransform: "uppercase" }} id={idAria} htmlFor={id}>
            <FormattedMessage
              id="zipcode"
              defaultMessage="Zipcode"
              description="Zipcode"
            />
          </label>

          <div className="input-group search-wrapper mb-2 mr-sm-2">
            <input
              id={id}
              className={isInvalid ? "form-control is-invalid" : "form-control"}
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={(e) => setWorkingZipcode(e.target.value)}
              onKeyDown={(e) => handleUpdateButtonPress(e, "input")}
            />
          </div>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn-ae btn-default mb-2 btn-block"
            style={{width: '100%'}}
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
             <FormattedMessage
              id="zipcode-button"
              defaultMessage="Update ZIP Code"
              description="Update ZIP Code Button"
            />
            
          </button>
        </div>
      </div>
      {(isInvalid || !ValidateFiveDigitCode(workingZipcode)) && (
        <div className="text-danger small">
          Error: {workingZipcode} is not a valid zipcode
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
