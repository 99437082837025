import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {FormattedMessage} from 'react-intl';
import EVCard from "../EVCard/EVCard";

const VehicleCarousel = ({ electricVehicles }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
          hasEvDetails
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  return (
    <section className="container pb-0" id="HomepageVehiclesCarousel">
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <p style={{fontFamily: 'Questa', fontWeight: '400', fontSize: '32px', lineHeight: '40px', textAlign: 'center', color: '#000'}}>
          <FormattedMessage 
            id="homepage.thereMoreVehicles"
            defaultMessage="There are more electric vehicles than ever. Discover yours."
            description="There are more electric vehicles title"
          />
        </p>
        <p style={{fontFamily: 'sans-serif', fontSize: '20px', lineHeight: '24px', color: '#707070', textAlign: 'center', maxWidth: '76ch'}}>
          <FormattedMessage 
            id="homepage.thereMoreVehiclesSubtitle"
            defaultMessage="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car."
            description="There are more electric vehicles subtitle"
          />
        </p>
      </div>
      <div className="row" style={{alignItems: 'center', justifyContent: 'center'}}>
        <Link to="/vehicles" style={{margin:"12px"}} className="btn btn-ae mt-10" role="button">
            <FormattedMessage 
                id="homepage.findVehicleCarousel.findEVButton"
                defaultMessage="Find Your Electric Vehicle"
                description="Find Your Electric Vehicle"
              />
          </Link>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
