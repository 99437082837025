import React from "react";
import PropTypes from "prop-types";

import IncentivePreferences from "./../IncentivesPreferences/IncentivePreferences";

import SlideMilesDrivenDaily from "../InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../InputComponents/SlideMaxBudget/SlideMaxBudget";
import SlideMinSeats from "../InputComponents/SlideMinSeats/SlideMinSeats";
import SelectChargerLevel from "../InputComponents/SelectChargerLevel/SelectChargerLevel";
import { Link } from "react-router-dom";
import info from "../../client_customizations/assets/images/icons/info.svg";

import { FormattedMessage, useIntl } from "react-intl";

import "./MatchScoreOptions.scss";

const MatchScoreOptions = ({ electricVehicles }) => {
  const intl = useIntl();

  const getMessage = React.useCallback(
    ({ id, defaultMessage }) => {
      return intl.formatMessage
        ? intl.formatMessage({
            id,
            defaultMessage,
          })
        : defaultMessage;
    },
    [intl]
  );

  const location = window.location;

  return (
    <>
      <div className="new-used-buttons-wrapper">
        <Link to="/vehicles">
          <button className={location.pathname === "/vehicles" ? "active" : ""}>
            <FormattedMessage
              id="matchScoreOptions.new"
              defaultMessage="New"
              description="New Button"
            />
          </button>
        </Link>
        <Link to="/used-vehicles">
          <button
            className={location.pathname === "/used-vehicles" ? "active" : ""}
          >
            <FormattedMessage
              id="matchScoreOptions.used"
              defaultMessage="Used"
              description="Used Button"
            />
          </button>
        </Link>
      </div>
      <div className="input-well MatchScoreOptions">
        <p className="h2">
          <FormattedMessage
            id="evs.matchScoreOptions"
            defaultMessage="Refine Match Score"
            description="Refine Match Score"
          />
          <img alt="" src={info} style={{ width: "16px" }} />
        </p>
        <SlideMilesDrivenDaily label={getMessage({
            id: "roundtripCommute",
            defaultMessage: "Roundtrip Commute",
          })} />

        <SlideMaxBudget  />

        <div style={{ height: "1rem" }} />

        <IncentivePreferences
          fromMatchScore
          electricVehicles={electricVehicles}
          titleText={getMessage({
            id: "personalizeIncentives",
            defaultMessage: "Personalize Incentives",
          })}
          btnText={getMessage({
            id: "personalizeIncentives",
            defaultMessage: "Personalize Incentives",
          })}
          btnSubtle
        />

        <div style={{ height: "1rem" }} />

        <SlideMinSeats />
        <SelectChargerLevel />
      </div>
    </>
  );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
