import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getFilteredData from "../../../../utils/Helpers/getFilteredData"
import IncentiveCatalog from "../../../../components/IncentiveCatalog/IncentiveCatalog";
import {FormattedMessage} from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  incentives = getFilteredData([
    { field: "grantor", value: "Federal", count: 1 },
    { field: "grantor_type", value: "State", count: 2 },
    { field: "grantor_type", value: "Power Supplier", count: 3 }
  ], incentives)

  return (
    <section className="container pb-0 incentive-bg" id="HomepageIncentives">
      <div className="row">
        <div className="col-sm-12 incentives-top">
            <h3 className="incentive-title">
              <FormattedMessage 
                  id="homepage.maximizePotential"
                  defaultMessage="Maximize potential EV incentives and tax credits."
                  description="Homepage Incentives Title"
              />
            </h3>
            <p className="lead incentive-subtitle">
            <FormattedMessage 
                id="homepage.incentivesSubTitle"
                defaultMessage="See how much you could save by getting behind the wheel of an EV, whether you are buying or leasing. Incentives are personalized for where you live."
                description="Homepage Incentives Sub Title"
                values= {{
                  lineBreak: <br />
                }}
              />
            </p>
            <Link to="/incentives" style={{margin: '16px 0 36px 0', width: '240px', fontWeight: '600', height: '36px', background: 'linear-gradient(90deg, #005996 0%, #1680A1 100%)'}} className="btn btn-ae" role="button">
              <FormattedMessage 
                  id="homepage.personalizeIncentives"
                  defaultMessage="Personalize Incentives"
                  description="Personalize Incentives Button"
                />
            </Link>
          </div>
        </div>
        <IncentiveCatalog incentives={incentives} category="all" />
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
