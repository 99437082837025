import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const optionNames = {
  0 : "Free",
  0.2 :"Low: $0.20/kWh",
  0.4 : "Medium: $0.40/kWh",
  0.6 :"High: $0.60/kWh"
};

const descriptionFn = val => {
  return `${optionNames[val]}`;
};

const SlideCostPublicCharging = ({
  id = "cost-public-charging-range",
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl()
  const label = intl.formatMessage ? intl.formatMessage({ id: "ev.costOfPublicCharing", defaultMessage: "Est. cost of public charging"}) : "Est. cost of public charging"  
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={userPrefs.get("costPublicCharging")}
      label={label}
      rangeMin={0}
      rangeMax={0.6}
      rangeStep={0.2}
      description={description}
      ariaControls="costs-chart-areas"
      handler={e => userPrefs.set({ costPublicCharging: e.target.value })}
      {...rest}
    />
  );
};

export default SlideCostPublicCharging;

SlideCostPublicCharging.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
