import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import globe from "../../../assets/images/globe.svg";
import logo from "../../../assets/images/logo.png";
import "./Header.scss";
import { FormattedMessage } from "react-intl";

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  const languages = process.env.REACT_APP_LANGUAGES.split(",");

  const onChangeLanguage = (value) => {
    changeLanguage(value);
  };

  return (
    <div className="Header">
      <div className="container">
        <Navbar expand="lg">
          <div style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem" }}>
            <a
              href="/"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <img
                src={logo}
                alt={process.env.REACT_APP_FULL_COMPANY_NAME}
                className="img-fluid"
                style={{ maxWidth: "116px" }}
              />
            </a>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="m-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED === "true" && (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                        id="home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_VEHICLES_ENABLED === "true" && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    className={
                      page === "vehicles"
                        ? "ev active"
                        : "ev"
                    }
                  >
                    <span>
                      <FormattedMessage
                        id="electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu left>
                    <DropdownItem
                      role="menuitem"
                      key={`nav-item-vehicles`}
                      title={page === "vehicles" ? "Active Page" : null}
                      tag={NavLink}
                      to={`/vehicles`}
                    >
                      <span>
                        <FormattedMessage
                          id="newVehiclesHeader"
                          defaultMessage="New EVs"
                          description="New Vehicles Header"
                        />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      role="menuitem"
                      key={`nav-item-used-vehicles`}
                      title={page === "used-vehicles" ? "Active Page" : null}
                      tag={NavLink}
                      to={`/used-vehicles`}
                    >
                      <span>
                        <FormattedMessage
                          id="usedElectricVehiclesHeader"
                          defaultMessage="Used EVs"
                          description="Used Vehicles Header"
                        />
                      </span>
                    </DropdownItem>
                    <DropdownItem
                      role="menuitem"
                      key={`nav-item-compare-vehicles`}
                      title={page === "compare-vehicles" ? "Active Page" : null}
                      tag={NavLink}
                      to={`/compare-vehicles`}
                    >
                      <span>
                        <FormattedMessage
                          id="compareVehiclesHeader"
                          defaultMessage="Compare EVs"
                          description="Compare Vehicles Header"
                        />
                      </span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {process.env.REACT_APP_PAGES_COMPARE_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/used-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}

              {process.env.REACT_APP_PAGES_DEALERS_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "dealers" ? "Active Page" : null}
                    to="/dealers"
                  >
                    <span>
                      <FormattedMessage
                        id="dealers"
                        defaultMessage="Dealers"
                        description="Dealers"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_CHARGING_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "charging-stations" ? "Active Page" : null}
                    to="/charging-stations"
                  >
                    <span>
                      <FormattedMessage
                        id="chargingStations"
                        defaultMessage="Charging Stations"
                        description="Charging Station Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={
                      page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK
                        ? "Active Page"
                        : null
                    }
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_FAQ_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>
                      <FormattedMessage
                        id="FAQ"
                        defaultMessage="FAQ"
                        description="FAQ Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_EVENTS_ENABLED === "true" && (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>
                      <FormattedMessage
                        id="events"
                        defaultMessage="Events"
                        description="Events Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              )}
              {process.env.REACT_APP_PAGES_VEHICLES_ENABLED === "true" && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className={"lang"}
                  >
                    <span>
                      <img alt="Select Language Icon" src={globe} />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu left>
                    {languages.map((lang) => (
                      <DropdownItem
                        role="menuitem"
                        key={`nav-item-lang-${lang}`}
                        title={language === lang ? "Active Page" : null}
                        tag={NavLink}
                        className={language === lang ? "active" : ""}
                        to={"/lang"}
                        onClick={(e) => {
                          e.preventDefault();
                          onChangeLanguage(lang);
                        }}
                      >
                        <span>{lang}</span>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
