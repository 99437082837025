import React from "react";
import PropTypes from "prop-types";

import "./SelectedCard.scss";
import PhoneIcon from "../../client_customizations/assets/images/icons/icn_phone.svg"
import LinkIcon from "../../client_customizations/assets/images/icons/icn_link.svg"

import GaTracker from "../../utils/GaTracker/GaTracker";
import {FormattedMessage} from 'react-intl';
import DealerPinSelected from "../../client_customizations/assets/images/icons/dealer-pin-selected-2.svg";

const SelectedCard = ({
  name,
  street,
  city,
  region,
  postcode,
  phone,
  oem,
  website,
  oemImage
}) => {
  const secondLineAddress = [city, region, postcode]
    .filter(el => el)
    .join(", ");

  const handleClickWebsite = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Website Link",
      label: name
    });
  };

  const handleClickPhone = () => {
    GaTracker.trackEvent({
      category: "Dealers",
      action: "Clicked Phone Link",
      label: name
    });
  };

  const iconMask = `url(${DealerPinSelected})`;

  return (
    <div className="SelectedCard">
      <div className="select-card-header">
        <div className="pin-selected"  style={{maskImage: iconMask, WebkitMaskImage: iconMask}}/>
        {oemImage && (
          <img
            src={`https://zappyassets.s3.amazonaws.com/img/oems/${oem}.png`}
            alt={oemImage.name || "Dealer image"}
            className="oem-image"
          />
        )}
      </div>
      {/* <h5>{name || ""}</h5> */}
      <p className="address-container">
        {street || ""}
        <br />
        {secondLineAddress || ""}
      </p>
      <div className="links-container">
        <div className="row">
          {phone && (
            <div className="col-6 contact-dealer">
              <img src={PhoneIcon} alt="" />
              &nbsp;
              <a href={`tel: ${phone}`} onClick={handleClickPhone}>
                <FormattedMessage 
                  id="dealers.contact"
                  defaultMessage="CONTACT"
                  description="Dealer Contact"
                />              
              </a>
            </div>
          )}
          {website && (
            <div className="col-6">
              <img src={LinkIcon} alt="" />
              &nbsp;
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClickWebsite}
              >
                <FormattedMessage 
                  id="dealers.website"
                  defaultMessage="WEBSITE"
                  description="Dealer Website"
                />    
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelectedCard.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postcode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  phone: PropTypes.string,
  website: PropTypes.string,
  oemImage: PropTypes.bool
};

export default SelectedCard;
