import React from "react";
import PropTypes from "prop-types";
import IncentiveCard from "./../IncentiveCard/IncentiveCard";
import LoadingSpinner from "./../LoadingSpinner/LoadingSpinner";

const IncentiveCatalog = ({ incentives, titleText }) => {

  if (!incentives) return <LoadingSpinner />;

  const GLENDALE_WATER_POWER = 'Glendale Water & Power';

  /** get all the Glendale elements */
  let glendaleGrantors = incentives.filter(e => e.grantor === GLENDALE_WATER_POWER);
  let toIndex = 0;

  if (glendaleGrantors) {

    /** get all the indexs of Glendale Water & Power */
    let indexs = incentives.reduce(function (a, e, i) {
      if (e.grantor === GLENDALE_WATER_POWER)
        a.push(i);
      return a;
    }, []);

    /** move elements to first  */
    indexs.forEach((item, index) => {
      incentives.splice(item, 1);
      incentives.splice(toIndex, 0, glendaleGrantors[index]);
      toIndex++;
    });

  }

  const renderCards = incentives
    ? incentives.map((incentive, index) => {
      return <IncentiveCard incentive={incentive} key={index} />;
    })
    : null;

  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-12">
        {titleText && <p className="h3 text-left">{titleText}</p>}
        <div className="d-flex flex-wrap" style={{ justifyContent: 'space-around' }} >
          {renderCards}
        </div>
      </div>
    </div>
  );
};

export default IncentiveCatalog;

IncentiveCatalog.propTypes = {
  incentives: PropTypes.array,
  titleText: PropTypes.element,
  includeTotal: PropTypes.bool
};
