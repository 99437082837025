import React from "react";
import PropTypes from "prop-types";
import "./UsedVehiclePanels.scss";
import { TabContent, TabPane } from "reactstrap";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import { Link } from "react-router-dom";
import sortIncentives from "../../../functions/incentives/Sort/sortIncentives"
import SelectVehicleYearFilter from "../../InputComponents/SelectVehicleYearFilter/SelectVehicleYearFilter"
import { FormattedMessage, useIntl } from 'react-intl';

const UsedVehiclePanels = ({ cars, car, setUsedEv }) => {
  const intl = useIntl()

  if (!cars) return null;

  const usedIncentives = car.incentives.filter(incentive => incentive.applicable_to_used_vehicles)

  const totalSavings = sortIncentives(usedIncentives).total("eligible-rebates");
  
  return (
    <>
      <div className="PricePanels">
        <TabContent activeTab={"1"}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem" style={{borderTop: 'none'}}>
                  <SelectVehicleYearFilter car={car} vehicles={cars} setUsedEv={setUsedEv}/> 
                </div>
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle text-center">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                    </p>
                    <p className="h1" style={{fontStyle: 'normal', fontWeight: '700', fontSize: '40px', textAlign: 'center'}}>
                      {`${FormatAsDollars(car.lowerBoundMsrp - totalSavings)} - ${FormatAsDollars(car.upperBoundMsrp - totalSavings)}`}
                    </p>
                  </div>
                </div>
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle text-center">
                      <FormattedMessage
                        id="vehicle.estimatedIncentives"
                        defaultMessage="ESTIMATED INCENTIVES"
                        description="Estimated Incentives"
                      />
                    </p>
                    <p className="h1 text-center" style={{fontStyle: 'normal', fontWeight: '700', fontSize: '40px', textAlign: 'center'}}>
                      {FormatAsDollars(totalSavings)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <div style={{background: '#f3f4f4', display: 'flex', alignItems: "center", justifyContent: 'center', borderBottom: 'none', marginTop: '16px'}}>
          <Link to="/dealers">
            <button
              className="btn-ae"
              style={{ width: '325px', background: 'linear-gradient(90deg, #005996 0%, #1680A1 100%)', height: '36px'}}
            >
              {intl.formatMessage({ id: "dealers.findDealers", defaultMessage: "Find Dealers"})}
            </button>
          </Link>
        </div>
    </>
  );
};

export default UsedVehiclePanels;

UsedVehiclePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
