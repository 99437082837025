import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DealerMap from "../../components/LocationMap/DealerMap";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

const DealerMapPage = ({ userLocation, title, ...props }) => {
  useEffect(() => {
    document.title = title;
  });

  return (
    <section className="container">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingBottom: "56px",
        }}
      >
        <p
          style={{
            fontFamily: "questa",
            fontWeight: "400",
            fontSize: "40px",
            lineHeight: "40px",
            textAlign: "center",
            color: "#000",
          }}
        >
          <FormattedMessage
            id="dealers.title"
            defaultMessage="Dealers"
            description="Dealers Page Title"
          />
        </p>
        <p
          style={{
            fontFamily: "proxima-nova",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#707070",
            textAlign: "center",
            maxWidth: "76ch",
          }}
        >
          <FormattedMessage
            id="dealers.subTitle"
            defaultMessage="Discover qualified EV dealers and click on each one to see {lineBreak} location and contact information."
            description="Dealers Page SubTitle"
            values={{
              lineBreak: <br />,
            }}
          />
        </p>
      </div>
      <DealerMap
        showHeader={false}
        userLocation={userLocation}
        title={title}
        {...props}
      />
    </section>
  );
};

export default DealerMapPage;

DealerMapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
