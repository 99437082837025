import React from "react";
import PropTypes from "prop-types";
import RenderAssumption from "../../components/RenderAssumption/RenderAssumption"
import ElectricRangeGraph from "../CostsCharts/ElectricRangeGraph"
import { FormattedMessage } from "react-intl"

const ElectricRange = ({ cars, title }) => {

  if (!cars || cars.length === 0) return null;

  const defaultTitle = `Electric Range`;

  const electricRangeAssumption = {
      title: "Range based on EPA Estimates",
      valueAsFormatted: ""
  }

  return (
    <section className="container">
      <div className="ElectricRange" id="ElectricRange">
        <div className="row">
          <div className="col-sm-12">
            <p className="h1 text-left">{title || defaultTitle}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
          <div className="input-well d-none d-md-block">
            <p className="RenderItemTitle" style={{ marginBottom: 10 }}>
              <FormattedMessage
                id="graph.electricRange.assumptions"
                defaultMessage="Assumptions"
                description="Assumptions"
              />
            </p>
            <RenderAssumption assumption={electricRangeAssumption} />
          </div>
        </div>
        <div
          id="costs-chart-areas"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>
            <FormattedMessage
              id="results.updated"
              defaultMessage="Results have been updated as of "
              description="Results have been updated as of "
            />{new Date().toLocaleString()}.
          </span>
        </div>
        <div className="col-md-8 col-lg-9">
          <ElectricRangeGraph cars={cars}/>
        </div>
      </div>
    </section>
  );
};

export default ElectricRange;

ElectricRange.propTypes = {
  cars: PropTypes.array,
  title: PropTypes.string
};
