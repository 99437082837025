import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* Left here to document poetential usage of Location map */
/* import MapLegend from "./Map/MapLegend";
import InputWorkingZipcodeForDealerMap from "../InputComponents/InputZipcode/InputZipcodeForChargingMap";
import ShowHideButton from "../ShowHideButton/ShowHideButton"; */

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import InputZipcodeForDealersMap from "../InputComponents/InputZipcode/InputZipcodeForDealersMap"
import Select from "../shared/InputElements/Select"
import SelectedCard from "../SelectedCard/SelectedCard"
import { FormattedMessage, useIntl } from 'react-intl';
import BaseGoogleMapReact from './BaseGoogleMapReact/BaseGoogleMapReact';
import DealerPin from "../../client_customizations/assets/images/icons/dealer-pin.svg";

import "./LocationMap.scss";

const DealerMarker = ({ isSelected, onClick, onMouseOver}) => {
  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)"
  };

  const iconMask = `url(${DealerPin})`;

  return (
    <span style={placeStyle} onClick={onClick} onMouseOver={onMouseOver}>
      <div className="pin"  style={{maskImage: iconMask, WebkitMaskImage: iconMask}}/>
    </span>
  );
};

const SelectOem = ({ dealers, onChange, selectedOem }) => {
  const oems = dealers.map(d => d.oem);
  const uniqueOems = [...new Set(oems)];
  const sortedOems = uniqueOems.sort((a, b) => a.localeCompare(b));
  const intl = useIntl();

  return (
      <Select
        value={selectedOem}
        handler={(e) => onChange(e.target.value)}
        label={intl.formatMessage({ id: "dealers.brands", defaultMessage: "Brands"})}
        ariaLabel={intl.formatMessage({ id: "dealers.brands", defaultMessage: "Brands"})}
        optionNames={[intl.formatMessage({ id: "dealers.allBrands", defaultMessage: "All Brands"}), ...sortedOems]}
        optionValues={["All Brands", ...sortedOems]}
      />
  );
};

SelectOem.propTypes = {
  dealers: PropTypes.array,
  onChange: PropTypes.func,
  selectedOem: PropTypes.string
};

const SelectSpecificDealer = ({ dealers, onChange, selectedDealerId, selectedOem }) => {
  let filteredDealers
  selectedOem !== "All Brands" ? filteredDealers = dealers.filter(dealer => dealer.oem === selectedOem) : filteredDealers = dealers

  const sortedDealers = filteredDealers.sort((a, b) => a.name.localeCompare(b.name));
  const sortedDealerNames = sortedDealers.map(d => d.name)

  const intl = useIntl();
  
  return (
    <div className="form-group">
      <Select
        value={selectedDealerId}
        handler={(e) => onChange(e.target.value)}
        label={intl.formatMessage({ id: "dealers", defaultMessage: "Dealers"})}
        ariaLabel={intl.formatMessage({ id: "dealers", defaultMessage: "Dealers"})}
        optionNames={[intl.formatMessage({ id: "dealers.allDealers", defaultMessage: "All Dealers"}), ...sortedDealerNames]}
        optionValues={["All Dealers", ...sortedDealerNames]}
      />
    </div>
  );
};

const DealerMap = ({
  dealerLocations,
  userLocation,
  ignoreDealersOtherThan,
  isVisible = true
}) => {
  const [selectedDealerId, setselectedDealerId] = useState('');
  const [selectedOem, setSelectedOem] = useState("All Brands");
  const [dealers, setDealers] = useState(dealerLocations);
  const [center, setCenter] = useState();

  useEffect( () => {
    setDealers(dealerLocations)
  }, [dealerLocations])

  useEffect(() => {
    if (!userLocation) return;
    setCenter({
      lat: parseFloat(userLocation.latitude),
      lng: parseFloat(userLocation.longitude)
    });
  }, [userLocation]);

  // const deselectDealer = () => setselectedDealerId(undefined);

  if (!dealers) return null

  const findDealer = () => dealerLocations.find(dealer => dealer.name === selectedDealerId)

  const changeOem = oem => {
    setSelectedOem(oem)
    if (oem === "All Brands") {
      setDealers(dealerLocations)
    } else {
      const newDealers = dealerLocations.filter(dealer => dealer.oem === oem)
      setDealers(newDealers)
    }
  }

  const changeDealer = dealerId => {
    if (dealerId === "All Dealers") {
      setselectedDealerId('')
    } else {
      setselectedDealerId(dealerId)
      const newDealer = dealerLocations.find(dealer => dealer.name === dealerId)
      setDealers([newDealer])
    }

  }

  const body = !userLocation ? (
    <div className="spinner-container">
      <LoadingSpinner />
    </div>
  ) : (
    <>
        <div className="col-sm-3 left-column">
            <div className="controls">
              <SelectOem
                dealers={dealerLocations}
                selectedOem={selectedOem}
                onChange={changeOem}
              />

              <SelectSpecificDealer
                dealers={dealerLocations}
                selectedDealerId={selectedDealerId}
                onChange={changeDealer}
                selectedOem={selectedOem}
              />

              <InputZipcodeForDealersMap />

            </div>
            {findDealer() && (
              <div className="selected-dealer-container">
                <SelectedCard
                  oemImage={true}
                  {...findDealer()}
                />
              </div>
            )}
        </div>
        <div className="col-sm-9">
          <div className="map-container">
            {isVisible && (
              <BaseGoogleMapReact
                zoom={13}
                center={center}
              >
                {dealers.map(dealer => (
                  <DealerMarker
                    key={dealer.handle}
                    lat={dealer.latitude}
                    lng={dealer.longitude}
                    isSelected={dealer.name === selectedDealerId}
                    onMouseOver={() => {setselectedDealerId(dealer.name)}}
                    onClick={() => {setselectedDealerId(dealer.name)}}
                  />
                ))}
              </BaseGoogleMapReact>
            )}
          </div>
        </div>
    </>
  );

  return (
    <div className="DealerMap">
      <div className="row hide-offscreen">
        <div className="col-sm-12">
          <h2>
            <FormattedMessage
              id="dealers"
              defaultMessage="Dealers"
              description="Dealers"
            />
          </h2>
        </div>
      </div>
      <div className="row dealerMap-content">
        {body}
      </div>
    </div>
  );
};

export default DealerMap;

DealerMap.propTypes = {
  chargingStations: PropTypes.array,
  userLocation: PropTypes.object,
  ignoreDealersOtherThan: PropTypes.bool
};
