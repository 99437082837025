import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ChargingMap from "../../components/LocationMap/ChargingMap";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";

const ChargingMapPage = ({
  userLocation,
  ip,
  uuid,
  history,
  dealerLocations,
  tabId,
  title,
  zipcode,
}) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_CHARGING_TITLE;
  });

  return (
    <section className="container">
      <Helmet>
        <title>{process.env.REACT_APP_PAGES_CHARGING_TITLE}</title>
      </Helmet>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingBottom: "56px",
        }}
      >
        <p
          style={{
            fontFamily: "Questa",
            fontWeight: "400",
            fontSize: "40px",
            lineHeight: "40px",
            textAlign: "center",
            color: "#000",
          }}
        >
          <FormattedMessage
            id="charging-stations.title"
            defaultMessage="Locate Charging Stations"
            description="Charging Stations Page Title"
          />
        </p>
        <p
          style={{
            fontFamily: "proxima-nova",
            fontSize: "20px",
            lineHeight: "24px",
            color: "#707070",
            textAlign: "center",
            maxWidth: "76ch",
          }}
        >
          <FormattedMessage
            id="charging-stations.subTitle"
            defaultMessage="It’s easier than ever to charge on the go. Enter your location to see all of the charging stations near you."
            description="Charging Stations Page SubTitle"
          />
        </p>
      </div>
      <ChargingMap showHeader={false} userLocation={userLocation} />
    </section>
  );
};

export default ChargingMapPage;

ChargingMapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
