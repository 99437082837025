import React from "react";
import PropTypes from "prop-types";

import sortIncentives from "../../functions/incentives/Sort/sortIncentives";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";
import { FormatAsDollars, FormatCarName } from "./../../utils/Helpers/Format";
import { FormattedMessage } from 'react-intl';
import './EVIncentives.scss'

const EVIncentives = ({ car, incentives, usedEv }) => {
  if (!incentives) return null;

  incentives = usedEv ? incentives.filter(incentive => incentive.applicable_to_used_vehicles) : incentives

  const totalSavings = sortIncentives(incentives).total("eligible-rebates");
  return (

    <section className="container" id="EVIncentives" style={{background: 'white'}}>
      <div className="EVIncentives">
        <div className="row">
          <div className="col-12">
            <p className="h1 mb-3 text-center EVIncentives-title" >
              <FormattedMessage 
                id="ev.incentivesTitle"
                defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                description="EV Incentives Title"
                values= {{
                  savings: <strong style={{color:"#598000"}}>{FormatAsDollars(totalSavings)}</strong>,
                  lineBreak: <br />,
                  car: FormatCarName(car)
                }}
              />
            </p>
            <IncentiveCatalog
              incentives={incentives}
              usedEv={usedEv}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVIncentives;

EVIncentives.propTypes = {
  car: PropTypes.object,
  incentives: PropTypes.array
};
