import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Range.scss";
import ToolTip from "../ToolTip/ToolTip";

const Range = (props) => {
  const {
    id,
    value,
    handler,
    label,
    rangeMin,
    rangeMax,
    rangeStep,
    description,
    tooltip,
    ariaControls,
    hasSupportingInfoBelow,
    disabled,
  } = props;

  const [workingValue, setWorkingValue] = useState(value);
  const [prevValue, setPrevValue] = useState(null);
  const [outputOffset, setOutputOffset] = useState(0);
  const outputRef = React.useRef(null);
  const inputRangeRef = React.useRef(null);

  const rangeValue = inputRangeRef.current ? inputRangeRef.current.value : null;

  const outputRefCallback = React.useCallback((outputInstance) => {
    if (outputInstance) {
      outputRef.current = outputInstance;
      setOutputOffset(getLabelOffset());
    }
  }, []);

  const rangeRefCallback = React.useCallback((rangeInstance) => {
    if (rangeInstance) {
      inputRangeRef.current = rangeInstance;
      setOutputOffset(getLabelOffset());
    }
  }, []);

  if (value !== prevValue) {
    setWorkingValue(value);
    setPrevValue(value);
  }

  const getLabelOffset = () => {
    const outputInstance = outputRef?.current;
    const rangeInstance = inputRangeRef?.current;

    if (!rangeInstance || !outputInstance) {
      return 0;
    }

    var off =
      (rangeInstance.clientWidth - 20) /
      (parseInt(rangeInstance.max) - parseInt(rangeInstance.min));
    var px =
      (rangeInstance.valueAsNumber - parseInt(rangeInstance.min)) * off -
      outputInstance.clientWidth / 2 +
      10;
    if (px < 0) {
      px = 0;
    } else if (px + outputInstance.clientWidth > rangeInstance.clientWidth) {
      px = rangeInstance.clientWidth - outputInstance.clientWidth;
    }

    return px;
  };

  React.useEffect(() => {
    if (rangeValue) {
      setOutputOffset(getLabelOffset());
    }
  }, [rangeValue]);

  return (
    <div
      className="form-group"
      style={{
        marginTop: "8px",
        marginBottom: hasSupportingInfoBelow ? "4px" : "1rem",
      }}
    >
      <label htmlFor={id} style={{fontWeight: 600, fontStyle: 'normal', fontSize: '12px'}}>
        {label}
        {tooltip && <ToolTip message={tooltip} id={id + "_tooltip"} />}
      </label>
      <input
        type="range"
        ref={rangeRefCallback}
        id={id}
        className="form-control-range input-range"
        value={workingValue}
        min={rangeMin}
        max={rangeMax}
        step={rangeStep}
        disabled={disabled}
        aria-controls={ariaControls}
        // On change, set workingState:
        onChange={(e) => setWorkingValue(e.target.value)}
        // When finished changing, set global app state:
        onMouseUp={(e) => handler(e)}
        onKeyUp={(e) => handler(e)}
        onTouchEnd={(e) => handler(e)}
      />
      <output
        ref={outputRefCallback}
        style={{
          marginLeft: `${outputOffset}px`,
        }}
        for={id}
      >
        {description(workingValue)}
      </output>
    </div>
  );
};

export default Range;

Range.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  label: PropTypes.string,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  rangeStep: PropTypes.number,
  description: PropTypes.func,
  disabled: PropTypes.bool,
  hasSupportingInfoBelow: PropTypes.bool,
  ariaControls: PropTypes.string,
  tooltip: PropTypes.string,
};
